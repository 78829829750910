import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../services/supabaseClient';

function Chats() {
    const navigate = useNavigate();
    const [chats, setChats] = useState([]);
    const [requestsList, setRequestsList] = useState([]);
    const [showChats, setShowChats] = useState(true);
    const [user, setUser] = useState(null); // Hinzugefügte Zeile

    useEffect(() => {
        fetchChats();
        fetchRequests();
    }, []);

    const fetchRequests = async () => {
        const { data: currentUserData } = await supabase.auth.getUser();
        if (!currentUserData.user) {
            console.error('Benutzer nicht eingeloggt');
            return;
        }
    
        const { data: requestsData, error } = await supabase
            .from('chats')
            .select('chat_id')
            .eq('participant_two', currentUserData.user.id)
            .eq('request_status', 'pending');
    
        if (error) {
            console.error('Fehler beim Abrufen von Chat-Anfragen:', error);
            return;
        }
    
        const latestRequests = {};
        await Promise.all(requestsData.map(async (request) => {
            const { data: messages, error: messageError } = await supabase
                .from('anonymous_messages')
                .select('*')
                .eq('chat_id', request.chat_id)
                .order('sent_timestamp', { ascending: false })
                .limit(1);
    
            if (messageError) {
                console.error('Fehler beim Abrufen der Nachrichten:', messageError);
                return;
            }
    
            if (messages.length > 0) {
                const message = messages[0];
                const partnerId = message.sender_id === currentUserData.user.id ? message.receiver_id : message.sender_id;
    
                const { data: partnerBoardData } = await supabase
                    .from('userboards')
                    .select('profile_image_url, board_title')
                    .eq('user_id', partnerId)
                    .single();
    
                latestRequests[request.chat_id] = {
                    lastMessage: message,
                    chatId: request.chat_id,
                    partnerId: partnerId,
                    profileImageUrl: partnerBoardData ? partnerBoardData.profile_image_url : 'default-profile-image-url',
                    boardTitle: partnerBoardData ? partnerBoardData.board_title : 'Unbekannt'
                };
            }
        }));
    
        setRequestsList(Object.values(latestRequests));
    };
    
    const acceptRequest = async (chatId) => {
        console.log(chatId)
        const { error } = await supabase
            .from('chats')
            .update({ request_status: 'accepted' })
            .eq('chat_id', chatId);
    
        if (error) {
            console.error('Fehler beim Akzeptieren der Anfrage:', error);
        } else {
            // Entfernen Sie die akzeptierte Anfrage aus der Liste
            setRequestsList(requestsList.filter(request => request.chat_id !== chatId));
            // Aktualisieren Sie die Chat-Liste
            fetchChats();
        }
    };
    
    const rejectRequest = async (chatId) => {
        const { error } = await supabase
            .from('chats')
            .update({ request_status: 'rejected' })
            .eq('chat_id', chatId);
    
        if (error) {
            console.error('Fehler beim Ablehnen der Anfrage:', error);
        } else {
            // Entfernen Sie die abgelehnte Anfrage aus der Liste
            setRequestsList(requestsList.filter(request => request.chat_id !== chatId));
        }
    };
    
    
    

    const formatDateTime = (timestamp) => {
        const date = new Date(timestamp);
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Intl.DateTimeFormat('de-DE', options).format(date);
    };

    const fetchChats = async () => {
        const { data: currentUserData } = await supabase.auth.getUser();
        if (currentUserData.user) {
            setUser(currentUserData.user);
        
            const { data: chatData, error: chatError } = await supabase
                .from('chats')
                .select('chat_id')
                .or(`participant_one.eq.${currentUserData.user.id},participant_two.eq.${currentUserData.user.id}`)
                .eq('request_status', 'accepted');
        
            if (chatError) {
                console.error('Fehler beim Abrufen der Chats:', chatError);
                return;
            }
    
            const latestChats = {};
            await Promise.all(chatData.map(async (chat) => {
                const { data: messages, error: messageError } = await supabase
                    .from('anonymous_messages')
                    .select('*')
                    .eq('chat_id', chat.chat_id)
                    .order('sent_timestamp', { ascending: false })
                    .limit(1);
        
                if (messageError) {
                    console.error('Fehler beim Abrufen der Nachrichten:', messageError);
                    return;
                }
        
                if (messages.length > 0) {
                    const message = messages[0];
                    const partnerId = message.sender_id === currentUserData.user.id ? message.receiver_id : message.sender_id;
    
                    const { data: partnerBoardData } = await supabase
                        .from('userboards')
                        .select('profile_image_url, board_title')
                        .eq('user_id', partnerId)
                        .single();

                    console.log(partnerBoardData)
    
                    latestChats[chat.chat_id] = {
                        lastMessage: message,
                        chatId: chat.chat_id,
                        partnerId: partnerId,
                        profileImageUrl: partnerBoardData ? partnerBoardData.profile_image_url : 'default-profile-image-url',
                        boardTitle: partnerBoardData ? partnerBoardData.board_title : 'Unbekannt'
                    };
                }
            }));
        
            setChats(Object.values(latestChats));
        }
    };
    
    const truncateMessage = (message, length) => {
        return message.length > length ? message.substring(0, length) + "..." : message;
    };    

    const navigateToChat = (chatId) => {
        navigate(`/chat/${chatId}`);
    };

    if (!user) return null;

    return (
        <div className="chats-container">
            <div className="top-bar">
                <span className="back-arrow" onClick={() => navigate('/profil')}>←</span>
                <img src="/qriosity-logo.png" alt="Logo" className="logo" />
            </div>
            <div className="chats-header">
                <button onClick={() => setShowChats(true)}>Chats</button>
                <button onClick={() => setShowChats(false)}>Anfragen</button>
            </div>
            <div className="search-field">
                <input type="text" placeholder="Suchen..." />
            </div>
            {showChats ? (
            <div className="chats-list">
            {chats.map((chat, index) => (
                <div key={index} className="chat-card" onClick={() => navigateToChat(chat.chatId)}>
            <div className="chat-profile-pic" style={{ backgroundImage: `url(${chat.profileImageUrl})` }}></div>
            <div className="chat-info">
                <p className="chat-title">{chat.boardTitle}</p>
                <p className="chat-preview">{truncateMessage(chat.lastMessage.message, 10)}</p>
            </div>
            <div className="chat-timestamp">
                {formatDateTime(chat.lastMessage.sent_timestamp)}
            </div>
        </div>

            ))}
            </div>
            ) : (
                <div className="requests-list">
                    {requestsList.map((request, index) => (
                        <div key={index} className="request-card">
                        <div className="request-profile-pic" style={{ backgroundImage: `url(${request.profile_image_url || 'default-profile-image-url'})` }}></div>
                        <div className="request-info">
                          <p className="request-title">{request.boardTitle}</p>
                          <p className="request-preview">{request.lastMessage.message}</p>
                        </div>
                            <button onClick={() => acceptRequest(request.chatId)} className="accept-btn">Akzeptieren</button>
                            <button onClick={() => rejectRequest(request.chatId)} className="reject-btn">Ablehnen</button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default Chats;
