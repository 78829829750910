import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../services/supabaseClient';

function IGRequests() {
  const navigate = useNavigate();
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { data, error } = await supabase
        .from('ig_requests')
        .select(`
          request_id,
          sender_id,
          request_timestamp,
          status,
          sender_profile:sender_profile_id!inner(ig_username)
        `)
        .eq('receiver_id', user.id);
  
      if (error) {
        console.error('Fehler beim Abrufen der IG-Anfragen:', error);
        return;
      }
  
      const requestsWithBoardInfo = await Promise.all(data.map(async request => {
        const { data: boardDataArray } = await supabase
          .from('userboards')
          .select('profile_image_url, board_title')
          .eq('user_id', request.sender_id);
  
        // Stellen Sie sicher, dass boardData ein Objekt ist
        const boardData = boardDataArray && boardDataArray.length > 0 ? boardDataArray[0] : {};
  
        return { ...request, ...boardData };
      }));
      console.log(data)
      setRequests(requestsWithBoardInfo || []);
    }
  };
  

  const handleAccept = async (requestId, senderId) => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) return;
  
    const { data: userProfile, error: userProfileError } = await supabase
      .from('ig_profiles')
      .select('ig_username')
      .eq('user_id', user.id)
      .single();
  
    if (userProfileError) {
      console.error('Fehler beim Abrufen des IG-Profils:', userProfileError);
      return;
    }
  
    if (!userProfile || !userProfile.ig_username) {
      navigate('/ig-verify');
      return;
    }
  
    const { error: updateError } = await supabase
    .from('ig_requests')
    .update({ status: 'accepted' })
    .eq('request_id', requestId);

    if (updateError) {
        console.error('Fehler beim Aktualisieren der Anfrage:', updateError);
      } else {
        // Senden einer Nachricht mit dem eigenen IG-Namen an den Sender der Anfrage
        await sendMessage(senderId, `Mein Instagram-Name ist: ${userProfile.ig_username}`);
        fetchRequests();
      }
    };
  
  
  const handleReject = async (requestId) => {
    console.log(requestId)
    const { error } = await supabase
      .from('ig_requests')
      .update({ status: 'rejected' })
      .eq('request_id', requestId);
  
    if (error) {
      console.error('Fehler beim Ablehnen der Anfrage:', error);
    } else {
      // Erfolgsmeldung und Aktualisieren der Anfragen
      console.log('Anfrage abgelehnt:', requestId);
      fetchRequests();
    }
  };

  const sendMessage = async (receiverId, message) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user || !user.id) {
        console.error('Authentifizierter Benutzer oder Benutzer-ID fehlt.');
        return;
      }
  
      if (!receiverId) {
        console.error('Empfänger-ID fehlt.');
        return;
      }
  
      // Erstellen des kombinierten UUID-Strings
      const combinedUUID = [user.id, receiverId].sort().join('-');
  
      let { data: existingChat } = await supabase
        .from('chats')
        .select('chat_id')
        .eq('participants_combined', combinedUUID);
  
      let chatId;
  
      if (!existingChat || existingChat.length === 0) {
        let { data: newChat, error: newChatError } = await supabase
          .from('chats')
          .insert([{ participant_one: user.id, participant_two: receiverId, participants_combined: combinedUUID }])
          .select('chat_id');
  
        if (newChatError || !newChat || newChat.length === 0) {
          console.error('Error creating new chat:', newChatError);
          return;
        }
  
        chatId = newChat[0].chat_id;
      } else {
        chatId = existingChat[0].chat_id;
      }
  
      let { error: messageError } = await supabase
        .from('anonymous_messages')
        .insert([{ 
          chat_id: chatId,
          sender_id: user.id,
          receiver_id: receiverId,
          message: message 
        }]);
  
      if (messageError) {
        console.error('Fehler beim Senden der Nachricht:', messageError);
        return;
      }
  
      console.log('Nachricht erfolgreich gesendet.');
    } catch (err) {
      console.error('Fehler beim Verarbeiten der Nachricht:', err);
    }
  };
  

  return (
    <div className="ig-requests-container">
      <div className="top-bar">
        <span className="back-arrow" onClick={() => navigate('/profil')}>←</span>
        <img src="/qriosity-logo.png" alt="Logo" className="logo" />
      </div>
      <div className="board-title-container igrequests-title-container">
        <h2>Anfragen</h2>
      </div>
      <div className="ig-requests-elements-container">
      {requests.map((request, index) => (
  <div key={index} className="ig-request-card">
    <div className="request-profile-pic" style={{ backgroundImage: `url(${request.profile_image_url || 'default-profile-image-url'})` }}></div>
    <div className="request-info">
      <p className="request-title">{request.board_title || 'Unbekannt'}</p>
      <p>{request.sender_profile.ig_username}</p>
    </div>
    <div className="request-actions">
    {request.status === 'rejected' ? (
        <p className="rejected-text">Abgelehnt</p>
      ) : request.status === 'accepted' ? (
        <p className="accepted-text">Akzeptiert</p>
      ) : (
        <>
          <button onClick={() => handleAccept(request.request_id, request.sender_id)} className="accept-btn">Akzeptieren</button>
          <button onClick={() => handleReject(request.request_id)} className="reject-btn">Ablehnen</button>
        </>
      )}
    </div>
  </div>
  ))}
        {requests.length === 0 && <p className="no-requests-info">Keine Anfragen.</p>}
      </div>
    </div>
  );
}

export default IGRequests;
