import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../services/supabaseClient';

function Profile() {
    const navigate = useNavigate();
    const [scanCount, setScanCount] = useState(0);
    const [todayScans, setTodayScans] = useState(0);
    const [notificationCount, setNotificationCount] = useState(0);
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
    const [profileImageUrl, setProfileImageUrl] = useState('');

    useEffect(() => {
        const fetchProfilePicture = async () => {
            const { data: { user } } = await supabase.auth.getUser();
            if (user) {
              const { data, error } = await supabase
                .from('userboards')
                .select('profile_image_url')
                .eq('user_id', user.id)
                .single();
        
              if (error) {
                console.error('Fehler beim Laden der Board-Daten:', error);
              } else if (data) {
                setProfileImageUrl(data.profile_image_url);
              }
            }
          };
        const fetchScans = async (user) => {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            // Heutige Scans
            const { count: todayCount, error: todayError } = await supabase
                .from('qr_code_scans')
                .select('*, qr_codes!inner(*)', { count: 'exact' })
                .eq('qr_codes.user_id', user.id)
                .gte('scan_timestamp', today.toISOString());

            // Gesamtanzahl der Scans
            const { count: totalCount, error: totalError } = await supabase
                .from('qr_code_scans')
                .select('*, qr_codes!inner(*)', { count: 'exact' })
                .eq('qr_codes.user_id', user.id);

            if (todayError || totalError) {
                console.error('Fehler beim Laden der Scan-Daten:', todayError || totalError);
            } else {
                setTodayScans(todayCount);
                setScanCount(totalCount);
            }
        };

        const fetchNotifications = async (user) => {
            const { data, error } = await supabase
                .from('ig_requests')
                .select('*', { count: 'exact' })
                .eq('receiver_id', user.id)
                .eq('status', 'pending'); // Stellen Sie sicher, dass der Status 'pending' ist
        
            if (error) {
                console.error('Fehler beim Abrufen der Benachrichtigungen:', error);
                return;
            }
        
            if (data) {
                setNotificationCount(data.length);
            } else {
                setNotificationCount(0); // Setzen Sie den Zähler auf 0, falls keine Daten vorhanden sind
            }
        };

        const fetchUnreadMessages = async (user) => {
            const { data, error } = await supabase
                .from('anonymous_messages')
                .select('*', { count: 'exact' })
                .eq('receiver_id', user.id)
                .eq('is_read', false); // Annahme, dass es ein Feld 'is_read' gibt
          
            if (error) {
                console.error('Fehler beim Abrufen der Nachrichten:', error);
                return;
            }
          
            if (data) {
                setUnreadMessageCount(data.length);
            } else {
                setUnreadMessageCount(0);
            }
          };
        

        const fetchData = async () => {
            const { data: { user } } = await supabase.auth.getUser();
            if (user) {
                fetchScans(user);
                fetchNotifications(user);
                fetchUnreadMessages(user);
            }
        };

        fetchData();
        fetchProfilePicture();
    }, []);

    const handleNotifications = () => {
        navigate('/chats'); // Navigieren zur Benachrichtigungsseite
    };
      
      
  
    const handleLogout = async () => {
      const { error } = await supabase.auth.signOut();
      if (error) {
        console.error('Fehler beim Logout:', error);
      } else {
        navigate('/'); // Weiterleiten zur Startseite oder Login-Seite
      }
    };
    const handleEditBoard = () => {
        navigate('/edit-board'); // Navigieren zur Seite "Board anpassen"
    };
    const handleViewBoard = () => {
        navigate('/view-board'); // Navigieren zur Seite "Board ansehen"
    };
    const handlescanNewCode = () => {
        navigate('/qr-scanner'); // Navigieren zur Seite "Board ansehen"
    };
    const handleScanStats = () => {
        navigate('/scan-stats'); // Navigieren zur Seite "Board ansehen"
    };
    const handleIGVerify = () => {
        navigate('/ig-requests'); // Navigieren zur Seite "Board ansehen"
    };

return (
    <div className="profile-container">
        <div className="top-bar">
            <img src="qriosity-logo.png" alt="Logo" className="logo" />
        </div>
        <div 
            className="profile-pic-container" 
            style={{ backgroundImage: `url(${profileImageUrl})`, backgroundSize: 'cover' }}
        >
        </div>
        <div className="card" onClick={handleViewBoard}>
            <div className="card-title">Profil</div>
        </div>
        <div className="card" onClick={handleNotifications}>
            <div className="card-title">Chat</div>
            {unreadMessageCount > 0 && 
                <span className="message-count">{unreadMessageCount}</span>
            }
        </div>
        <div className="card-container">
            <div className="card" onClick={handleScanStats}>
                <div className="card-title">Scan Statistik</div>
            </div>
            <div className="card" onClick={handleIGVerify}>
                <div className="card-title">IG Anfragen</div>
                {notificationCount > 0 && 
                    <span className="notification-count">{notificationCount}</span>
                }
            </div>
        </div>
        <div className="card" onClick={handlescanNewCode}>
            <div className="card-title">Scan New Code</div>
        </div>
        <button className="logoutBtn" onClick={handleLogout}>Logout</button>
    </div>
);
}

export default Profile;
