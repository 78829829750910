import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/auth/LogIn';
import Register from './components/auth/Register';
import Profile from './components/profile/Profile'; 
import ViewBoard from './components/board/ViewBoard';
import EditBoard from './components/board/EditBoard';
import QRCodeScanner from './components/profile/QRCodeScanner'; 
import AdminPanel from './components/admin/AdminPanel';
import PublicBoard from './components/board/PublicBoard';
import IntermediatePage from './components/redirection/IntermediatePage';
import ScanStats from './components/profile/ScanStats';
import IGVerification from './components/profile/IGVerification';
import Notifications from './components/profile/Notifications';
import IGRequests from './components/profile/IGRequests';
import Chat from './components/chat/Chat';
import Chats from './components/chat/Chats';


import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profil" element={<Profile />} />
          <Route path="/edit-board" element={<EditBoard />} />
          <Route path="/view-board" element={<ViewBoard />} />
          <Route path="/qr-scanner" element={<QRCodeScanner />} />
          <Route path="/admin-panel" element={<AdminPanel />} />
          <Route path="/public-board/:userId" element={<PublicBoard />} />
          <Route path="/qr/:qrCodeKey" element={<IntermediatePage />} />
          <Route path="/scan-stats" element={<ScanStats />} />
          <Route path="/ig-verify" element={<IGVerification />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/ig-requests" element={<IGRequests />} />
          <Route path="/chat/:chatId" element={<Chat />} />
          <Route path="/chats" element={<Chats />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
