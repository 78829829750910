import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../services/supabaseClient';

function IGVerify() {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const redirectPath = queryParams.get('redirect');
  const [igUsername, setIgUsername] = useState('');

  const handleVerify = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { error } = await supabase
        .from('ig_profiles')
        .upsert({ user_id: user.id, ig_username: igUsername, is_verified: true });

      if (error) {
        console.error('Fehler bei der Verifizierung des IG-Kontos:', error);
      } else {
        alert('IG-Konto verifiziert!');
        if (redirectPath) {
            navigate(decodeURIComponent(redirectPath));
          } else {
            navigate('/profil'); 
          }
      }
    }
  };

  return (
    <div className="ig-verify-container">
      <h1>Instagram-Verifizierung</h1>
      <input
        type="text"
        placeholder="Instagram-Benutzername"
        value={igUsername}
        onChange={(e) => setIgUsername(e.target.value)}
      />
      <button onClick={handleVerify}>Verifizieren</button>
    </div>
  );
}

export default IGVerify;
