import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useZxing } from 'react-zxing';
import { supabase } from '../../services/supabaseClient';

export const QRCodeScanner = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("Platziere den Code gut sichtbar im Kamerafeld");
  const { ref } = useZxing({
    onDecodeResult(result) {
      handleQRCodeScanned(result.getText());
    },
  });

  const handleQRCodeScanned = async (scannedResult) => {
    const { data: currentUserData } = await supabase.auth.getUser();
    if (!currentUserData.user) {
      setMessage('Kein Benutzer eingeloggt.');
      return;
    }
    const user_id = currentUserData.user.id; // Benutzer-ID extrahieren
  
    const { data, error } = await supabase
      .from('qr_codes')
      .select('user_id')
      .eq('code_data', scannedResult)
      .single();
  
    if (error) {
      setMessage('Fehler beim Abrufen von QR-Codes');
      return;
    }
  
    if (!data.user_id) {
      const boardUrl = `https://qriosity.eu/public-board/${user_id}`;
      const { error: updateError } = await supabase
        .from('qr_codes')
        .update({ user_id: user_id, board_url: boardUrl })
        .eq('code_data', scannedResult);
  
      if (updateError) {
        setMessage('Fehler beim Aktualisieren des QR-Codes');
      } else {
        setMessage('QR-Code erfolgreich dem Benutzer zugeordnet.');
      }
    } else {
      setMessage('QR-Code bereits einem Benutzer zugeordnet.');
    }
  };

  return (
    <div className="qr-scanner-container">
      <div className="top-bar">
        <span className="back-arrow" onClick={() => navigate('/profil')}>←</span>
        <img src="/qriosity-logo.png" alt="Logo" className="logo" />
      </div>
      <div className="qr-scanner-title">
        <h2>Scan New Code</h2>
      </div>
      <div className="qr-scanner-elements">
        <video ref={ref} className="qr-scanner-video" />
        <p className="qr-scanner-message">{message}</p>
      </div>
    </div>
  );
};

export default QRCodeScanner;
