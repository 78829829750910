import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../services/supabaseClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons';
import ReactCountryFlag from "react-country-flag";

function ViewBoard() {
  const navigate = useNavigate();
  const [boardTitle, setBoardTitle] = useState('');
  const [boardElements, setBoardElements] = useState([]);
  const [boardOwnerId, setBoardOwnerId] = useState([]);
  const [showAnonymousMessage, setShowAnonymousMessage] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [predefinedQuestions, setPredefinedQuestions] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [languages, setLanguages] = useState([]);
  const [showLanguageOverlay, setShowLanguageOverlay] = useState(false);

  useEffect(() => {
    const fetchBoardData = async () => {
      await fetchPredefinedQuestions();
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        setBoardOwnerId(user.id);
        const { data, error } = await supabase
          .from('userboards')
          .select('board_title, board_elements, profile_image_url, languages')
          .eq('user_id', user.id)
          .single();

        if (error) {
          console.error('Fehler beim Laden der Board-Daten:', error);
        } else if (data) {
          setBoardTitle(data.board_title);
          setBoardElements(data.board_elements || []);
          setProfileImageUrl(data.profile_image_url);
          setLanguages(data.languages || []);
          const anonymousMessageElement = data.board_elements.find(element => element.type === 'anonymous_message_request');
          if (anonymousMessageElement) {
            setShowAnonymousMessage(true);
          }
        }
      }
    };

    fetchBoardData();
  }, []);

  const handleCardClick = (url) => {
    window.open(url, '_blank');
  };

  const checkIGVerification = async (userId) => {
    const { data, error } = await supabase
      .from('ig_profiles')
      .select('is_verified')
      .eq('user_id', userId)
      .single();

    if (error) {
      console.error('Fehler beim Überprüfen der IG-Verifizierung:', error);
      return false;
    }

    return data ? data.is_verified : false;
  };

  // Platzhalter-Funktion zum Senden einer IG-Anfrage
  const sendIGRequest = (userId, ownerId) => {
    // Implementieren Sie Ihre eigene Logik hier
    console.log('IG-Anfrage senden von', userId, 'an', ownerId);
  };

  const handleIGRequestClick = async () => {
    const { data: { user } } = await supabase.auth.getUser();
  
    if (!user) {
      alert('Bitte loggen Sie sich ein, um IG anzufragen.');
      navigate('/');
      return;
    }
  
    const isIGVerified = await checkIGVerification(user.id);
    if (!isIGVerified) {
      navigate('/ig-verify'); // Navigieren zur IG-Verifizierungsseite
      return;
    }
  
    sendIGRequest(user.id, boardOwnerId);
    alert('IG-Anfrage gesendet.');
  };

  const switchTab = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const fetchPredefinedQuestions = async () => {
    const { data, error } = await supabase
      .from('userboard_questions')
      .select('*');

    if (error) {
      console.error('Fehler beim Laden der vordefinierten Fragen:', error);
    } else {
      setPredefinedQuestions(data);
    }
  };

  const getQuestionText = (questionId) => {
    if (predefinedQuestions.length === 0) {
      return 'Fragen werden geladen...';
    }
  
    const questionIdNum = parseInt(questionId, 10); // Konvertieren in eine Zahl
    const question = predefinedQuestions.find(q => q.question_id === questionIdNum);
    return question ? question.question_text : 'Frage nicht gefunden';
  };


// Funktion zum Anzeigen des Overlays
const displayLanguageOverlay = () => {
  if (languages.length > 0) {
    setShowLanguageOverlay(true);
  }
};

// Funktion zum Schließen des Overlays
const closeLanguageOverlay = () => {
  setShowLanguageOverlay(false);
};

const flagWrapperStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  overflow: 'hidden',
  backgroundColor: 'white'
};

const flagStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'cover'
};
  
  

  return (
    <div className="view-board-container">
      <div className="top-bar">
        <span className="back-arrow" onClick={() => navigate('/profil')}>←</span>
        <img src="qriosity-logo.png" alt="Logo" className="logo" />
      </div>
      <div className="profile-edit-container">
        <div className="profile-languages-container">
            <div className="profile-pic-wrapper">
              <div 
                className="profile-pic-container" 
                style={{ backgroundImage: `url(${profileImageUrl})`, backgroundSize: 'cover' }}
              >
              </div>
            </div>
            <div className="language-circles-wrapper">
              {languages.length > 0 && (
                <>
                  <div style={flagWrapperStyle}>
                    <ReactCountryFlag
                      countryCode={languages[0].code}
                      svg
                      style={flagStyle}
                      title={languages[0].code}
                    />
                  </div>
                  {languages.length > 1 && (
                    <div
                      className="language-quantity-circle"
                      onClick={displayLanguageOverlay}
                    >
                      +{languages.length - 1}
                    </div>
                  )}
                </>
              )}
            </div>
        </div>
        <button className="edit-button" onClick={() => navigate('/edit-board')}>Edit</button>
      </div>
      <div className="board-title-container">
        <h2>{boardTitle}</h2>
      </div>
      <div className="board-elements-container">
        <div className="tabs">
          <button onClick={() => switchTab(0)} className={`tab ${activeTab === 0 ? 'active' : ''}`}>
            <FontAwesomeIcon icon={faEnvelope} />
          </button>
          <button onClick={() => switchTab(1)} className={`tab ${activeTab === 1 ? 'active' : ''}`}>
            <FontAwesomeIcon icon={faUser} />
          </button>
        </div>
        {activeTab === 0 && (
        <div className="contact-info">
        {boardElements.map((element, index) => {
        if (element.type === 'link') {
          return (
            <div key={index} className="card link-card" onClick={() => handleCardClick(element.content)}>
              <h3>{element.title}</h3>
              <p>{element.content}</p>
            </div>
          );
        } else if (element.type === 'ig_request') { // Ändern Sie 'ig_request_button' zu 'ig_request'
          return (
            <button key={index} onClick={handleIGRequestClick} className="ig-request-btn">
              IG anfragen
            </button>
          );
        } else if (element.type === 'anonymous_message_request') {
          return (
            <div key={index} className="send-message-btn">
              Nachricht senden
              {/* Implementieren Sie hier die Logik für das Senden einer anonymen Nachricht */}
            </div>
          );
  }
  return null;
        })}
        </div>
        )}
        {activeTab === 1 && (
        <div className="faq-container">
          {boardElements.filter(element => element.type === 'faq').map((faq, index) => (
            <div key={index} className="faq-card">
              <h3>{getQuestionText(faq.question_id)}</h3>
              <p>{faq.answer}</p>
            </div>
          ))}
        </div>
      )}
      </div>
      {showLanguageOverlay && (
  <div className="language-overlay">
    <div className="language-overlay-card">
      <h2 className="language-overlay-title">Sprachdetails</h2>
      <div className="languages-display">
        {languages.map((lang, index) => (
          <div key={index} className="language-item">
            <div style={flagWrapperStyle}>
              <ReactCountryFlag
                countryCode={lang.code}
                svg
                style={flagStyle}
                title={lang.code} 
              />
            </div>
            <span className="language-item-language-name">{lang.name } - {lang.level}</span>
          </div>
        ))}
      </div>
      <button onClick={closeLanguageOverlay} className="close-overlay-btn">Schließen</button>
    </div>
  </div>
)}
    </div>
  );
}

export default ViewBoard;


