import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../services/supabaseClient';

function Notifications() {
    const navigate = useNavigate();
    const [requests, setRequests] = useState([]);
    const [messages, setMessages] = useState([]);
    const [showAllRequests, setShowAllRequests] = useState(false);
    const [showAllMessages, setShowAllMessages] = useState(false);

    useEffect(() => {
        fetchRequests();
        fetchMessages();
    }, [showAllRequests, showAllMessages]);

    const fetchRequests = async () => {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
            let query = supabase
                .from('ig_requests')
                .select(`
                    request_timestamp,
                    status,
                    sender_profile:sender_profile_id!inner(ig_username)  // Verknüpft ig_requests mit ig_profiles über sender_profile_id
                `)
                .eq('receiver_id', user.id);

            if (!showAllRequests) {
                query = query.eq('status', 'pending');
            }

            query = query.order('request_timestamp', { ascending: false });

            const { data, error } = await query;

            if (error) {
                console.error('Fehler beim Abrufen der IG-Anfragen:', error);
            } else {
                setRequests(data || []);
                if (!showAllRequests) {
                    await markRequestsAsSeen(user.id);
                }
            }
        }
    };

    const fetchMessages = async () => {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
            const { data: messages, error } = await supabase
                .from('anonymous_messages')
                .select('*')
                .eq('receiver_id', user.id)
                .order('sent_timestamp', { ascending: false });
    
            if (error) {
                console.error('Fehler beim Abrufen der Nachrichten:', error);
            } else {
                const latestMessages = {};
                messages.forEach(message => {
                    if (!latestMessages[message.chat_id]) {
                        latestMessages[message.chat_id] = message;
                    }
                });
    
                setMessages(Object.values(latestMessages));
            }
        }
    };
    
    const navigateToChat = (chatId) => {
        navigate(`/chat/${chatId}`);
    };  
      


    const markRequestsAsSeen = async (userId) => {
        const { error } = await supabase
            .from('ig_requests')
            .update({ status: 'seen' })
            .eq('receiver_id', userId)
            .eq('status', 'pending');

        if (error) {
            console.error('Fehler beim Aktualisieren der Anfragen:', error);
        }
    };

    return (
        <div className="notifications-container">
            <h1>Benachrichtigungen</h1>

            <div className="section">
                <h2>Instagram-Anfragen</h2>
                <div className="toggle-buttons">
                    <button className={!showAllRequests ? 'active' : ''} onClick={() => setShowAllRequests(false)}>Nur neue Anfragen</button>
                    <button className={showAllRequests ? 'active' : ''} onClick={() => setShowAllRequests(true)}>Alle Anfragen anzeigen</button>
                </div>
                <div className="requests-list">
                    {requests.map((request, index) => (
                        <div key={index} className="request-card">
                            <p>Instagram-Anfrage von {request.sender_profile.ig_username} am {new Date(request.request_timestamp).toLocaleString()}</p>
                        </div>
                    ))}
                </div>
            </div>

            <div className="section">
                <h2>Nachrichten</h2>
                <div className="toggle-buttons">
                    <button className={!showAllMessages ? 'active' : ''} onClick={() => setShowAllMessages(false)}>Nur neue Nachrichten</button>
                    <button className={showAllMessages ? 'active' : ''} onClick={() => setShowAllMessages(true)}>Alle Nachrichten anzeigen</button>
                </div>
                <div className="messages-list">
                {messages.map((message, index) => (
                    <div key={index} className="message-card" onClick={() => navigateToChat(message.chat_id)}>
                        <p>Anonyme Nachricht: {message.message}</p>
                    </div>
                ))}
            </div>
            </div>

            {requests.length === 0 && messages.length === 0 && <p className="no-requests-info">Keine Anfragen oder Nachrichten.</p>}
            <button onClick={() => navigate('/profil')} className="back-btn">Zurück zum Profil</button>
        </div>
    );
}

export default Notifications;

