import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../services/supabaseClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUser, faTrash } from '@fortawesome/free-solid-svg-icons';
import ReactCountryFlag from "react-country-flag";


function EditBoard() {
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const [showLink, setShowLink] = useState(false);
    const [link, setLink] = useState('');
    const [linkTitle, setLinkTitle] = useState('');
    const [boardTitle, setBoardTitle] = useState('');
    const [showIGRequest, setShowIGRequest] = useState(false);
    const [allowAnonymousMessages, setAllowAnonymousMessages] = useState(false);
    const [profileImageUrl, setProfileImageUrl] = useState('');
    const [predefinedQuestions, setPredefinedQuestions] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [showLanguageOverlay, setShowLanguageOverlay] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [faq, setFaq] = useState([]);

    const languageOptions = [
      { code: 'DE', name: 'Deutsch' },
      { code: 'GB', name: 'Englisch' },
      { code: 'ES', name: 'Spanish' },
      { code: 'FR', name: 'Französisch' },
      { code: 'PT', name: 'Portugiesisch' },
      { code: 'CN', name: 'Chinesisch' },
    ];

    useEffect(() => {
      const fetchBoardData = async () => {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
          const { data, error } = await supabase
            .from('userboards')
            .select('board_title, board_elements, profile_image_url, languages')
            .eq('user_id', user.id)
            .single();
    
          if (error) {
            console.error('Fehler beim Laden der Board-Daten:', error);
          } else if (data) {
            setBoardTitle(data.board_title);
            setProfileImageUrl(data.profile_image_url);
    
            const linkElement = data.board_elements.find(element => element.type === 'link');
            if (linkElement) {
              setLinkTitle(linkElement.title);
              setLink(linkElement.content);
              setShowLink(true);
            }
    
            const igRequestElement = data.board_elements.find(element => element.type === 'ig_request');
            if (igRequestElement) {
              setShowIGRequest(true);
            }
            const anonymousMessageElement = data.board_elements.find(element => element.type === 'anonymous_message_request');
            if (anonymousMessageElement) {
              setAllowAnonymousMessages(true);
            }
            if (data && data.languages) {
              setLanguages(data.languages);
            }
            // Laden und Umwandeln der FAQ-Daten
            const loadedFaqs = data.board_elements
              .filter(element => element.type === 'faq')
              .map(faq => ({
                question: faq.question_id,
                answer: faq.answer
              }));
            setFaq(loadedFaqs);
          }
        }
      };
    
      fetchBoardData();
      fetchPredefinedQuestions();
    }, []);

    const handleImageUpload = async (event) => {
      const file = event.target.files[0];
      const { data: { user } } = await supabase.auth.getUser();
    
      if (user && file) {
        const fileExt = file.name.split('.').pop();
        const fileName = `${user.id}-${Date.now()}.${fileExt}`;
        const filePath = `${user.id}/${fileName}`;
    
        let { error: uploadError } = await supabase.storage
          .from('profile-pictures')
          .upload(filePath, file);
    
        if (uploadError) {
          console.error('Fehler beim Hochladen:', uploadError);
          return;
        }
    
        const { data: urlData, error: urlError } = await supabase.storage
          .from('profile-pictures')
          .getPublicUrl(filePath);
    
        if (urlError) {
          console.error('Fehler beim Abrufen der URL:', urlError);
          return;
        }
    
        const publicURL = urlData.publicUrl;
    
        console.log('url' + publicURL)
        console.log('userid' + user.id)
        const { error: updateError } = await supabase
          .from('userboards')
          .update({ profile_image_url: publicURL })
          .eq('user_id', user.id);
    
        if (updateError) {
          console.error('Fehler beim Aktualisieren der UserBoards:', updateError);
        } else {
          setProfileImageUrl(publicURL); // Setzen des Bild-URL-Zustands
        }
      }
    };
    

  const handleSave = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const board_elements = showLink ? [{ type: "link", content: link, title: linkTitle }] : [];
      if (showIGRequest) {
        board_elements.push({ type: "ig_request" }); // IG-Anfrage-Button hinzufügen
      }
      if (allowAnonymousMessages) {
        board_elements.push({ type: "anonymous_message_request" });
      }

      faq.forEach(f => {
        if (f.question && f.answer) {
          board_elements.push({ 
            type: "faq", 
            question_id: f.question, 
            answer: f.answer });
        }
      });
      const { error } = await supabase
        .from('userboards')
        .upsert({ 
          user_id: user.id, 
          board_title: boardTitle, 
          board_elements: board_elements,
          languages: languages
        }, 
        { 
          onConflict: 'user_id' 
        });
  
      if (error) {
        console.error('Fehler beim Speichern der Board-Daten:', error);
      } else {
        alert('Board-Daten gespeichert');
      }
    }
  };

  const handleProfilePictureClick = () => {
    fileInputRef.current.click();
  };

  const switchTab = (tabIndex) => {
    setActiveTab(tabIndex);
  };

// Funktion zum Hinzufügen einer neuen Frage-Antwort-Karte
const addFaqCard = () => {
  setFaq([...faq, { question: '', answer: '' }]);
};

// Funktion zum Aktualisieren einer spezifischen Frage oder Antwort
const updateFaq = (index, key, value) => {
  const newFaq = [...faq];
  newFaq[index][key] = value;
  setFaq(newFaq);
};

const removeFaqCard = (index) => {
  setFaq(faq.filter((_, i) => i !== index));
};

const fetchPredefinedQuestions = async () => {
  const { data, error } = await supabase
    .from('userboard_questions')
    .select('*');

  if (error) {
    console.error('Fehler beim Laden der vordefinierten Fragen:', error);
  } else {
    setPredefinedQuestions(data);
  }
};

// Beispiel für das Aktualisieren einer Sprache
const updateLanguage = (index, updatedLanguage) => {
  const newLanguages = [...languages];
  newLanguages[index] = updatedLanguage;
  setLanguages(newLanguages);
};


const addLanguage = (language) => {
  setLanguages([...languages, language]);
};

const removeLanguage = (index) => {
  setLanguages(languages.filter((_, i) => i !== index));
};

const handleAddLanguage = (languageCode) => {
  const language = languageOptions.find(lang => lang.code === languageCode);
  if (language && !languages.some(l => l.code === languageCode)) {
    addLanguage({ code: language.code, name: language.name, level: 'Anfänger' });
  }
};

const openLanguageOverlay = () => {
  setShowLanguageOverlay(true);
};

// Funktion zum Schließen des Spracheinstellungs-Overlays
const closeLanguageOverlay = () => {
  setShowLanguageOverlay(false);
};

const setLanguageLevel = (index, level) => {
  const newLanguages = [...languages];
  newLanguages[index].level = level;
  setLanguages(newLanguages);
};

const displayLanguageOverlay = () => {
  if (languages.length > 0) {
    setShowLanguageOverlay(true);
  }
};

const flagWrapperStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  overflow: 'hidden',
  backgroundColor: 'white'
};

const flagStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'cover'
};

  return (
    <div className="edit-board-container">
      <div className="top-bar">
        <span className="back-arrow" onClick={() => navigate('/profil')}>←</span>
        <img src="qriosity-logo.png" alt="Logo" className="logo" />
      </div>
      <div className="profile-edit-container">
      <div className="profile-languages-container">
      <div className="profile-pic-wrapper" onClick={handleProfilePictureClick}>
        <div
          className="profile-pic-container"
          style={{ backgroundImage: `url(${profileImageUrl})`, backgroundSize: 'cover' }}>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleImageUpload}
            style={{ display: 'none' }}
          />
        </div>
      </div>
      <div className="language-circles-wrapper">
        {languages.length > 0 ? (
          <>
            <div style={flagWrapperStyle}>
              <ReactCountryFlag
                countryCode={languages[0].code}
                svg
                style={flagStyle}
                title={languages[0].code}
              />
            </div>
            <div
              className="language-quantity-circle"
              onClick={displayLanguageOverlay}
            >
              +{languages.length - 1}
            </div>
          </>
        ) : (
          <div
            className="language-circle"
            onClick={() => setShowLanguageOverlay(true)}
          >
            {'+'}
          </div>
        )}
      </div>
    </div>
    <button className="edit-button" onClick={handleSave}>Speichern</button>
      </div>
      <div className="board-title-container">
        <input 
          type="text" 
          value={boardTitle} 
          onChange={(e) => setBoardTitle(e.target.value)} 
          placeholder="Board-Titel" 
        />
      </div>
    {/* Board-Einstellungen */}
    <div className="board-settings-container">
      <div className="tabs">
      <button onClick={() => switchTab(0)} className={`tab ${activeTab === 0 ? 'active' : ''}`}>
        <FontAwesomeIcon icon={faEnvelope} />
      </button>
      <button onClick={() => switchTab(1)} className={`tab ${activeTab === 1 ? 'active' : ''}`}>
        <FontAwesomeIcon icon={faUser} />
      </button>
      </div>
      {activeTab === 0 && (
      <div className="board-settings-container-inner">
        {showLink ? (
          <div className="link-settings link-card card">
            <input type="text" value={linkTitle} onChange={(e) => setLinkTitle(e.target.value)} placeholder="Link-Titel" className="link-title" />
            <input type="text" value={link} onChange={(e) => setLink(e.target.value)} placeholder="Link" className="link-url" />
          </div>
        ) : (
          <button onClick={() => setShowLink(true)} className="edit-board-link add-link-btn">Link hinzufügen</button>
      )}
      {/* IG-Anfrage-Einstellungen */}
      <button 
        className={`edit-board-ig-request ${showIGRequest ? '' : 'disabled'}`} 
        onClick={() => setShowIGRequest(!showIGRequest)}>
        IG anfragen
      </button>
      {/* Anonyme Nachrichten-Einstellungen */}
      <button 
      className={`edit-board-message ${allowAnonymousMessages ? '' : 'disabled'}`} 
      onClick={() => setAllowAnonymousMessages(!allowAnonymousMessages)}>
      Nachricht senden
    </button>
    </div>
    )}
    {activeTab === 1 && (
    <div className="faq-container">
      {faq.map((item, index) => (
        <div key={index} className="faq-card">
          <select 
            value={item.question}
            onChange={(e) => updateFaq(index, 'question', e.target.value)}
          >
            {predefinedQuestions.map((q) => (
              <option key={q.question_id} value={q.question_id}>{q.question_text}</option>
            ))}
          </select>
          <textarea  
            value={item.answer}
            onChange={(e) => updateFaq(index, 'answer', e.target.value)}
            placeholder="Antwort"
          ></textarea>
          <button onClick={() => removeFaqCard(index)} className="delete-question-btn">Löschen</button>
        </div>
      ))}
      <button onClick={addFaqCard} className="add-question-btn">+ Neue Frage hinzufügen</button>
    </div>
    )}
    </div>
      {/* Overlay für Spracheinstellungen */}
      {showLanguageOverlay && (
        <div className="language-overlay">
          <div className="language-overlay-card">
            <h2 className="language-overlay-title">Spracheinstellungen</h2>
            <select onChange={(e) => handleAddLanguage(e.target.value)}>
              {languageOptions.map(lang => (
                <option key={lang.code} value={lang.code}>{lang.name}</option>
              ))}
            </select>
            {/* Anzeige der gewählten Sprachen mit farbigen Kreisen */}
            <div className="languages-display">
            {languages.map((lang, index) => (
            <div key={index} className="language-item">
              <div style={flagWrapperStyle}>
                <ReactCountryFlag
                  countryCode={lang.code}
                  svg
                  style={flagStyle}
                  title={lang.code} 
                />
              </div>
                  <span className="language-item-language-name">{lang.name}</span>
                  <select onChange={(e) => setLanguageLevel(index, e.target.value)} style={{ marginLeft: '10px', flexShrink: 0 }}>
                    <option value="Anfänger">Anfänger</option>
                    <option value="Fortgeschritten">Fortgeschritten</option>
                    <option value="Fließend">Fließend</option>
                  </select>
                  <button onClick={() => removeLanguage(index)} style={{ marginLeft: '10px', flexShrink: 0 }}><FontAwesomeIcon icon={faTrash} /></button>
                </div>
              ))}
            </div>
            <button onClick={closeLanguageOverlay} style={{ marginTop: '20px' }} className="close-overlay-btn">Schließen</button>
          </div>
        </div>
      )}
    </div>
);
}

export default EditBoard;
