import React from 'react';

const MessageOverlay = ({ messageText, handleMessageChange, handleSend, closeOverlay }) => {
  return (
    <div className="overlay">
      <div className="overlay-content">
        <textarea
          value={messageText}
          onChange={handleMessageChange}
          placeholder="Deine Nachricht"
        ></textarea>
        <button onClick={handleSend}>Senden</button>
        <button onClick={closeOverlay}>Schließen</button>
      </div>
    </div>
  );
};

export default MessageOverlay;
