import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../../services/supabaseClient';

function Chat() {
  const navigate = useNavigate();
  const { chatId } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const [chatPartner, setChatPartner] = useState({ profileImageUrl: '', boardTitle: '' });

  useEffect(() => {
    fetchMessages();
  }, [chatId]);

  useEffect(() => {
    const fetchUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setCurrentUser(user);
    };

    fetchUser();
  }, []);

  useEffect(() => {
    const fetchChatPartnerDetails = async () => {
      const { data: currentUserData } = await supabase.auth.getUser();
      if (!currentUserData.user) return;
  
      const { data: chatDetails, error: chatDetailsError } = await supabase
        .from('chats')
        .select('*')
        .eq('chat_id', chatId)
        .single();
  
      if (chatDetailsError) {
        console.error('Fehler beim Abrufen der Chatdetails:', chatDetailsError);
        return;
      }
  
      const partnerId = chatDetails.participant_one === currentUserData.user.id ? chatDetails.participant_two : chatDetails.participant_one;
  
      const { data: partnerBoardData } = await supabase
        .from('userboards')
        .select('profile_image_url, board_title') // Annahme: board_title ist in user_profiles vorhanden
        .eq('user_id', partnerId)
        .single();
  
      setChatPartner({
        partnerId: partnerId,
        profileImageUrl: partnerBoardData ? partnerBoardData.profile_image_url : 'default-profile-image-url',
        boardTitle: partnerBoardData ? partnerBoardData.board_title : 'Unbekannt'
      });
    };
  
    fetchChatPartnerDetails();
  }, [chatId]);
  

  const fetchMessages = async () => {
    const { data, error } = await supabase
      .from('anonymous_messages')
      .select('*')
      .eq('chat_id', chatId)
      .order('sent_timestamp', { ascending: true });
  
    if (error) {
      console.error('Fehler beim Abrufen der Nachrichten:', error);
    } else {
      setMessages(data || []);
      markMessagesAsRead(data);
    }
  };

  const markMessagesAsRead = async (messages) => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) return;
  
    const unreadMessages = messages.filter(message => message.receiver_id === user.id && !message.is_read);
    if (unreadMessages.length > 0) {
      const unreadMessageIds = unreadMessages.map(msg => msg.message_id);
      const { error } = await supabase
        .from('anonymous_messages')
        .update({ is_read: true })
        .in('message_id', unreadMessageIds);
  
      if (error) {
        console.error('Fehler beim Aktualisieren der Nachrichtenstatus:', error);
      }
    }
  };

  const handleSend = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;
  
      // Ermitteln des anderen Teilnehmers im Chat
      const { data: chatDetails, error: chatDetailsError } = await supabase
        .from('chats')
        .select('*')
        .eq('chat_id', chatId)
        .single();
  
      if (chatDetailsError) throw chatDetailsError;
  
      const receiverId = (chatDetails.participant_one === user.id) ? chatDetails.participant_two : chatDetails.participant_one;
  
      // Nachricht senden
      const { error: messageError } = await supabase
        .from('anonymous_messages')
        .insert([{ chat_id: chatId, sender_id: user.id, receiver_id: receiverId, message: newMessage }]);
  
      if (messageError) throw messageError;
  
      console.log('Nachricht erfolgreich gesendet.');
      setNewMessage('');
      fetchMessages(); // Aktualisieren der Nachrichtenliste
    } catch (err) {
      console.error('Fehler beim Verarbeiten der Nachricht:', err);
    }
  };
  

  return (
    <div className="chat-container">
      <div className="top-bar">
        <span className="back-arrow" onClick={() => navigate('/profil')}>←</span>
        <img src="/qriosity-logo.png" alt="Logo" className="logo" />
      </div>
      <div className="profile-pic-container" style={{ backgroundImage: `url(${chatPartner.profileImageUrl || 'default-profile-image-url'})`, backgroundSize: 'cover' }}></div>
      <div className="board-title-container">
        <h2>{chatPartner.boardTitle}</h2>
      </div>
      <div className="board-elements-container chat-elements-container">
        <div className="messages-list">
        {messages.map((msg, index) => {
          const isOwnMessage = msg.sender_id === currentUser?.id; // Verwendung von currentUser statt user
          const messageClass = isOwnMessage ? "message-card own-message" : "message-card other-message";
          return (
            <div key={index} className={messageClass}>
              <span className="timestamp">{new Date(msg.sent_timestamp).toLocaleString()}</span>
              <p>{msg.message}</p>
            </div>
          );
        })}
      </div>
      <div className="message-input">
        <textarea
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Schreiben Sie eine Nachricht"
        ></textarea>
        <button onClick={handleSend}>Senden</button>
      </div>
      </div>
    </div>
  );
}

export default Chat;
