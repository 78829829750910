import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../services/supabaseClient';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement);

function ScanStats() {
  const navigate = useNavigate();
  const [stats, setStats] = useState({});

  useEffect(() => {
    const fetchStats = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 9); // 10 Tage zurück, einschließlich heute
        startDate.setHours(0, 0, 0, 0);
  
        const { data, error } = await supabase
          .from('qr_code_scans')
          .select('scan_timestamp, qr_codes!inner(*)')
          .eq('qr_codes.user_id', user.id)
          .gte('scan_timestamp', startDate.toISOString());
  
        if (error) {
          console.error('Fehler beim Abrufen der Scan-Statistiken:', error);
          return;
        }
  
        // Umkehrung der Schleife zur Erstellung der Labels
        let formattedData = {};
        for (let i = 9; i >= 0; i--) {
          let date = new Date();
          date.setDate(date.getDate() - i);
        formattedData[date.toLocaleDateString()] = 0;
        }
  
        data.forEach(scan => {
          const date = new Date(scan.scan_timestamp).toLocaleDateString();
          formattedData[date] = (formattedData[date] || 0) + 1;
        });
  
        setStats(formattedData);
      }
    };
  
    fetchStats();
  }, []);
  
  

  const data = {
    labels: Object.keys(stats),
    datasets: [
      {
        label: 'Anzahl der Scans',
        data: Object.values(stats),
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
      },
    ],
  };

  return (
    <div className="scan-stats-container">
      <div className="top-bar">
        <span className="back-arrow" onClick={() => navigate('/profil')}>←</span>
        <img src="/qriosity-logo.png" alt="Logo" className="logo" />
      </div>
      <div className="scan-stats-title">
        <h2>Scan Statistik</h2>
      </div>
      <div className="scan-stats-elements">
        <Bar data={data} />
      </div>
    </div>
  );
}

export default ScanStats;
