import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../../services/supabaseClient';

function IntermediatePage() {
  const { qrCodeKey } = useParams();
  const [hasTracked, setHasTracked] = useState(false);

  useEffect(() => {
    const checkQRCode = async () => {  
      console.log('check' + qrCodeKey)
      if (qrCodeKey && !hasTracked) {
        const query = `https://qriosity.eu/qr/${qrCodeKey}`;
        const { data, error } = await supabase
          .from('qr_codes')
          .select('id, user_id, board_url')
          .eq('code_data', query)
          .single();

        if (data && !hasTracked) {
          await supabase
            .from('qr_code_scans')
            .insert([{ qr_code_id: data.id }]);
          setHasTracked(true);

          if (data.user_id && data.board_url) {
            window.location.href = data.board_url;
          }
        }

        if (error) {
          console.error('Fehler beim Abrufen des QR-Codes:', error);
        }
      }
    };

    checkQRCode();
  }, [qrCodeKey, hasTracked]);

  return (
    <div>
      <p>Überprüfung des QR-Codes...</p>
      {/* Weitere UI-Elemente */}
    </div>
  );
}

export default IntermediatePage;
