import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import { supabase } from '../../services/supabaseClient';
import { v4 as uuidv4 } from 'uuid';

function AdminPanel() {
  const [inputPassword, setInputPassword] = useState('');
  const [authorized, setAuthorized] = useState(false);
  const adminPassword = "IhrGeheimesPasswort";
  const [qrCodeData, setQRCodeData] = useState(''); 
  const [fgColor, setFgColor] = useState('#000000');

  const [bgColorR, setBgColorR] = useState(0);
  const [bgColorG, setBgColorG] = useState(0);
  const [bgColorB, setBgColorB] = useState(0);
  const [bgColorA, setBgColorA] = useState(1);

  const handleLogin = () => {
    if (inputPassword === adminPassword) {
      setAuthorized(true);
    } else {
      alert('Falsches Passwort');
    }
  };
//#ff9fe4

  const handleGenerateQRCode = async () => {
    const customStartDate = new Date('2023-07-27').getTime();
    const uniqueId = `${Date.now() - customStartDate}-${uuidv4().substring(0, 7)}`;    
    const qrCodeData = `https://qriosity.eu/qr/${uniqueId}`;
    setQRCodeData(qrCodeData);
  
    // Speichern des QR-Codes in Supabase mit der generischen URL
    const { error } = await supabase.from('qr_codes').insert([{ code_data: qrCodeData }]);
    if (error) console.error('Fehler beim Speichern in Supabase:', error);
  
    // Optional: Direkter Download des QR-Codes
    const qrCanvas = document.getElementById('qrCodeCanvas');
    const pngUrl = qrCanvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `QRCode-${uniqueId}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const rgbaToString = (r, g, b, a) => `rgba(${r}, ${g}, ${b}, ${a})`;

  if (!authorized) {
    return (
      <div>
        <input type="password" value={inputPassword} onChange={(e) => setInputPassword(e.target.value)} />
        <button onClick={handleLogin}>Login</button>
      </div>
    );
  }

  return (
    <div>
      <h1>Admin Panel</h1>
      <div>
        <label>Vordergrundfarbe:</label>
        <input type="color" value={fgColor} onChange={(e) => setFgColor(e.target.value)} />
        <label>Hintergrundfarbe:</label>
        <div>
          <input type="range" min="0" max="255" value={bgColorR} onChange={(e) => setBgColorR(e.target.value)} /> Rot
          <input type="range" min="0" max="255" value={bgColorG} onChange={(e) => setBgColorG(e.target.value)} /> Grün
          <input type="range" min="0" max="255" value={bgColorB} onChange={(e) => setBgColorB(e.target.value)} /> Blau
          <input type="range" min="0" max="1" step="0.01" value={bgColorA} onChange={(e) => setBgColorA(e.target.value)} /> Alpha
        </div>
      </div>
      <button onClick={handleGenerateQRCode}>QR-Code generieren</button>
      {qrCodeData && (
        <QRCode
          id="qrCodeCanvas"
          value={qrCodeData}
          size={1000}
          includeMargin={true}
          bgColor={rgbaToString(bgColorR, bgColorG, bgColorB, bgColorA)}
          fgColor={fgColor}
          level="H"
        />
      )}
    </div>
  );
}

export default AdminPanel;
