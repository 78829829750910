import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../../services/supabaseClient';

function Register({ onToggleForm }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [showImpressum, setShowImpressum] = useState(false);
  const [showDatenschutz, setShowDatenschutz] = useState(false);

  const handleRegister = async (e) => {
    e.preventDefault();
    if (password !== passwordConfirm) {
      console.error('Passwörter stimmen nicht überein');
      return;
    }
    const { user, error } = await supabase.auth.signUp({ email, password });
    if (error) {
      console.error('Registrierungsfehler:', error);
    } else {
      console.log('Erfolgreich registriert:', user);
      // Weiterleiten zu einer anderen Seite oder Statusaktualisierung
    }
  };

  return (
    <div className="register-container">
      <img src="qriosity-logo.png" alt="Logo" className="logo" />
      <form onSubmit={handleRegister} className="form">
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="E-Mail" className="input-field" />
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Passwort" className="input-field" />
        <input type="password" value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} placeholder="Passwort bestätigen" className="input-field" />
        <button type="submit" className="submit-button">Registrieren</button>
      </form>
      <div className="toggle-text">
        Bereits ein Konto? <Link to="/">Anmelden</Link>
      </div>
      <p className="private-disclaimer">Do not create an account yet</p>
      <div className="accordion">
        <button onClick={() => setShowImpressum(!showImpressum)}>Impressum</button>
        {showImpressum && <div className="accordion-content">
        Maximilian Knöfel.<br />
        Moltkeplatz 9.<br />
        45138 Essen<br /><br />

        E-Mail: maximilianjfk@gmail.com<br /><br />

        Inhaltlich Verantwortlich für diese Website<br />
        Maximilian Knöfel
        </div>}
      </div>
      <div className="accordion">
        <button onClick={() => setShowDatenschutz(!showDatenschutz)}>Datenschutz</button>
        {showDatenschutz && <div className="accordion-content">
        Allgemeiner Hinweis und Pflichtinformationen<br /><br />

        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website:<br /><br />

        Maximilian Knöfel.<br />
        Moltkeplatz 9.<br />
        45138 Essen<br /><br /><br />

        Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).<br /><br />

        Widerruf Ihrer Einwilligung zur Datenverarbeitung<br />
        Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde
        Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit: https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.<br /><br />

        Recht auf Datenübertragbarkeit<br />
        Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
        Recht auf Auskunft, Berichtigung, Sperrung, Löschung
        Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.
        </div>}
      </div>
    </div>
  );
}

export default Register;
