import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../../services/supabaseClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons';
import MessageOverlay from './MessageOverlay';
import ReactCountryFlag from "react-country-flag";

function BoardView() {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [boardTitle, setBoardTitle] = useState('');
  const [boardElements, setBoardElements] = useState([]);
  const [boardOwnerId, setBoardOwnerId] = useState([]);
  const [showAnonymousMessage, setShowAnonymousMessage] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [predefinedQuestions, setPredefinedQuestions] = useState([]);
  const [showMessageOverlay, setShowMessageOverlay] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [showLanguageOverlay, setShowLanguageOverlay] = useState(false);

  useEffect(() => {
    const fetchBoardData = async () => {
      await fetchPredefinedQuestions();
      const { data, error } = await supabase
        .from('userboards')
        .select('board_title, board_elements, profile_image_url, languages, user_id')
        .eq('user_id', userId)
        .single();
  
      if (error) {
        console.error('Fehler beim Laden des Boards:', error);
      } else if (data) {
        setBoardTitle(data.board_title);
        setBoardElements(data.board_elements || []);
        setProfileImageUrl(data.profile_image_url);
        setLanguages(data.languages || []);
        setBoardOwnerId(data.user_id);
  
        // Überprüfen, ob das Element für anonyme Nachrichtenanfragen vorhanden ist
        const anonymousMessageElement = data.board_elements.find(element => element.type === 'anonymous_message_request');
        if (anonymousMessageElement) {
          setShowAnonymousMessage(true);
        }
      }
    };
  
    fetchBoardData();
  }, [userId]);
  

  const handleCardClick = (url) => {
    window.open(url, '_blank');
  };

  const checkIGVerification = async (userId) => {
    const { data, error } = await supabase
      .from('ig_profiles')
      .select('is_verified')
      .eq('user_id', userId)
      .single();

    if (error) {
      console.error('Fehler beim Überprüfen der IG-Verifizierung:', error);
      return false;
    }

    return data ? data.is_verified : false;
  };

  const sendIGRequest = async (userId, ownerId) => {
    try {
      // Abrufen der sender_profile_id des Benutzers
      const { data: userProfile, error: userProfileError } = await supabase
        .from('ig_profiles')
        .select('profile_id')
        .eq('user_id', userId)
        .single();
  
      if (userProfileError) throw userProfileError;
  
      // Einfügen der IG-Anfrage mit sender_profile_id
      const { error } = await supabase.from('ig_requests').insert([
        { sender_id: userId, receiver_id: ownerId, sender_profile_id: userProfile.profile_id }
      ]);
  
      if (error) {
        console.error('Fehler beim Senden der IG-Anfrage:', error);
        alert('Fehler beim Senden der IG-Anfrage.');
      } else {
        console.log('IG-Anfrage erfolgreich gesendet von', userId, 'an', ownerId);
        alert('IG-Anfrage gesendet.');
      }
    } catch (err) {
      console.error('Fehler beim Verarbeiten der IG-Anfrage:', err);
    }
  };
  
  

  const handleIGRequestClick = async () => {
    const { data: { user } } = await supabase.auth.getUser();
  
    if (!user) {
      const currentPath = `/public-board/${userId}`; // Der aktuelle Pfad der PublicBoard-Seite
      navigate(`/?redirect=${encodeURIComponent(currentPath)}`);
      return;
    }
  
    const isIGVerified = await checkIGVerification(user.id);
    if (!isIGVerified) {
      const currentPath = `/public-board/${userId}`;
      navigate(`/ig-verify?redirect=${encodeURIComponent(currentPath)}`);
      return;
    }
  
    sendIGRequest(user.id, boardOwnerId);
  };

  const handleSendMessageCardClick = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) {
      const currentPath = `/public-board/${userId}`;
      navigate(`/?redirect=${encodeURIComponent(currentPath)}`);
      return;
    }

    setShowMessageOverlay(true);
  };

  const handleMessageChange = useCallback((event) => {
    setMessageText(event.target.value);
  }, []);

  const closeOverlay = () => {
    setShowMessageOverlay(false);
  };

  const handleSend = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user || !user.id) {
        console.error('Authentifizierter Benutzer oder Benutzer-ID fehlt.');
        return;
      }
  
      if (!boardOwnerId) {
        console.error('Board-Besitzer-ID fehlt.');
        return;
      }
  
      // Erstellen des kombinierten UUID-Strings
      const combinedUUID = [user.id, boardOwnerId].sort().join('-');
  
      let { data: existingChat } = await supabase
        .from('chats')
        .select('chat_id')
        .eq('participants_combined', combinedUUID);
  
      let chatId;
  
      if (!existingChat || existingChat.length === 0) {
        let { data: newChat, error: newChatError } = await supabase
          .from('chats')
          .insert([{ participant_one: user.id, participant_two: boardOwnerId, participants_combined: combinedUUID, request_status: 'pending' }])
          .select('chat_id');
  
        if (newChatError || !newChat || newChat.length === 0) {
          console.error('Error creating new chat:', newChatError);
          throw new Error("Fehler beim Erstellen eines neuen Chats.");
        }
  
        chatId = newChat[0].chat_id;
      } else {
        chatId = existingChat[0].chat_id;
      }
      console.log('Chat ID being used for anonymous_messages:', chatId);

      let { error: messageError } = await supabase
        .from('anonymous_messages')
        .insert([{ 
          chat_id: chatId,
          sender_id: user.id,
          receiver_id: boardOwnerId,
          message: messageText 
        }]);
  
      if (messageError) {
        console.error('Fehler beim Senden der Nachricht:', messageError);
        throw messageError;
      }
  
      alert('Nachricht gesendet.');
      setMessageText('');
      setShowMessageOverlay(false);
    } catch (err) {
      console.error('Fehler beim Verarbeiten der Nachricht:', err);
    }
  };

  const fetchPredefinedQuestions = async () => {
    const { data, error } = await supabase
      .from('userboard_questions')
      .select('*');

    if (error) {
      console.error('Fehler beim Laden der vordefinierten Fragen:', error);
    } else {
      setPredefinedQuestions(data);
    }
  };

  const getQuestionText = (questionId) => {
    if (predefinedQuestions.length === 0) {
      return 'Fragen werden geladen...';
    }
  
    const questionIdNum = parseInt(questionId, 10); // Konvertieren in eine Zahl
    const question = predefinedQuestions.find(q => q.question_id === questionIdNum);
    return question ? question.question_text : 'Frage nicht gefunden';
  };


// Funktion zum Anzeigen des Overlays
const displayLanguageOverlay = () => {
  if (languages.length > 0) {
    setShowLanguageOverlay(true);
  }
};

// Funktion zum Schließen des Overlays
const closeLanguageOverlay = () => {
  setShowLanguageOverlay(false);
};

const flagWrapperStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  overflow: 'hidden',
  backgroundColor: 'white'
};

const flagStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'cover'
};

  return (
    <div className="view-board-container">
      <div className="top-bar">
        <img src="/qriosity-logo.png" alt="Logo" className="logo" />
      </div>
      <div className="profile-edit-container">
        <div className="profile-languages-container">
          <div className="profile-pic-wrapper">
            <div
              className="profile-pic-container"
              style={{ backgroundImage: `url(${profileImageUrl})`, backgroundSize: 'cover' }}
            ></div>
          </div>
          <div className="language-circles-wrapper">
              {languages.length > 0 && (
                <>
                  <div style={flagWrapperStyle}>
                    <ReactCountryFlag
                      countryCode={languages[0].code}
                      svg
                      style={flagStyle}
                      title={languages[0].code}
                    />
                  </div>
                  {languages.length > 1 && (
                    <div
                      className="language-quantity-circle"
                      onClick={displayLanguageOverlay}
                    >
                      +{languages.length - 1}
                    </div>
                  )}
                </>
              )}
          </div>
        </div>
      </div>
      <div className="board-title-container">
        <h2>{boardTitle}</h2>
      </div>
      <div className="board-elements-container">
        <div className="tabs">
          <button onClick={() => setActiveTab(0)} className={`tab ${activeTab === 0 ? 'active' : ''}`}>
            <FontAwesomeIcon icon={faEnvelope} />
          </button>
          <button onClick={() => setActiveTab(1)} className={`tab ${activeTab === 1 ? 'active' : ''}`}>
            <FontAwesomeIcon icon={faUser} />
          </button>
        </div>
        {activeTab === 0 && (
        <div className="contact-info">
        {boardElements.map((element, index) => {
          if (element.type === 'link') {
            return (
              <div key={index} className="card link-card" onClick={() => handleCardClick(element.content)}>
                <h3>{element.title}</h3>
                <p>{element.content}</p>
              </div>
            );
          } else if (element.type === 'ig_request') {
            return (
              <button key={index} onClick={handleIGRequestClick} className="ig-request-btn">
                Instagram anfragen
              </button>
            );
          } else if (element.type === 'anonymous_message_request' && showAnonymousMessage) {
            return (
              <div key={index} className="send-message-btn" onClick={handleSendMessageCardClick}>
                <h3>Nachricht senden</h3>
              </div>
            );
          }
          return null;
        })}
        {showMessageOverlay && (
        <MessageOverlay
          messageText={messageText}
          handleMessageChange={handleMessageChange}
          handleSend={handleSend}
          closeOverlay={closeOverlay}
        />
        )}
        </div>
        )}
        {activeTab === 1 && (
          <div className="faq-container">
            {boardElements.filter(element => element.type === 'faq').map((faq, index) => (
              <div key={index} className="faq-card">
                <h3>{getQuestionText(faq.question_id)}</h3>
                <p>{faq.answer}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      {showLanguageOverlay && (
  <div className="language-overlay">
    <div className="language-overlay-card">
      <h2 className="language-overlay-title">Sprachdetails</h2>
      <div className="languages-display">
        {languages.map((lang, index) => (
          <div key={index} className="language-item">
            <div style={flagWrapperStyle}>
              <ReactCountryFlag
                countryCode={lang.code}
                svg
                style={flagStyle}
                title={lang.code} 
              />
            </div>
            <span className="language-item-language-name">{lang.name } - {lang.level}</span>
          </div>
        ))}
      </div>
      <button onClick={closeLanguageOverlay} className="close-overlay-btn">Schließen</button>
    </div>
  </div>
)}
<div className="bottom-promo">
        <div className="promo-content">
          <div className="promo-text">
            <h2>Du willst auch so einen Hoodie?</h2>
            <a href="#" className="shop-now-link">Shop now</a>
          </div>
          <div className="promo-image">
            <img src="/hoodie.png" alt="Hoodie" />
          </div>
        </div>
      </div>
    </div>
  );
  
}

export default BoardView;
